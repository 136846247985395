<template>
  <div class="home-case">
    <div class="home-case__content">
      <button
        ref="shakerRef"
        class="shaker"
        type="button"
        :aria-label="t('case-random')"
        @click="onShake"
      >
        <GCtaRounded name="random" size="small" />
      </button>
      <div class="case">
        <div ref="headlineRef" class="case__headline section-label">
          {{ t('project') }}
        </div>
        <h2
          :key="currentCase.slug"
          ref="titleRef"
          class="h1-case split-title case__title"
        >
          {{ cleanBackslash(currentCase.title) }}
        </h2>
        <!-- <router-link ref="linkRef" class="case__link" :to="currentCase.url">
          <SvgSprite symbol="icons-reply" aria-label="retry" size="56" />
        </router-link> -->
        <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
        <div ref="linkRef" class="case__link">
          <GBtn
            tag="a"
            size="small"
            :href="currentCase.url"
            @click.prevent="onCaseClick"
            >{{ content.cases.ctaLabel }}</GBtn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import { computed, nextTick, ref, onMounted, PropType } from 'vue'
import { useRouter } from 'vue-router'

import { createSplitTitle, SplitTitle } from '@/animations/splitTitle'
import { setColors } from '@/modules/colors'
import { Colors } from '@/types'
import { CaseHighlight } from '@/types/views/cases'
import { Content } from '@/types/views/home'
import { shuffle } from '@/utils/array'
import { wait } from '@/utils/async'
import { logger } from '@/utils/logger'
import { cleanBackslash } from '@/utils/string'
import { useChromeStore } from '@/stores/chrome'

import { poster } from '@/components/global/Poster.vue'

gsap.registerPlugin(ScrollToPlugin)

const props = defineProps({
  content: {
    type: Object as PropType<Content>,
    required: true,
  },
})

/* eslint-disable func-call-spacing */
const emit = defineEmits<{
  (e: 'color-change', colors: Colors): void
}>()

const router = useRouter()

const cases: CaseHighlight[] = shuffle(props.content.cases.items)

const caseIndex = ref(0)
const currentCase = computed(() => cases[caseIndex.value])

const shakerRef = ref<HTMLElement>()
const headlineRef = ref<HTMLElement>()
const titleRef = ref<HTMLElement>()
const linkRef = ref<HTMLElement>()

const { t } = useChromeStore()

let splitTitle: SplitTitle

emit('color-change', currentCase.value.colors)

const createAppearTl = () => {
  const items = []

  items.push(shakerRef.value)

  items.push(headlineRef.value)
  // items.push(linkRef.value)

  return gsap
    .timeline({
      paused: true,
    })
    .set(titleRef.value || null, { opacity: 1 })
    .add('start')
    .fromTo(
      items,
      {
        y: '-20px',
        opacity: 0,
      },
      {
        duration: 0.35,
        ease: 'power3.out',
        y: '0px',
        opacity: 1,
        stagger: 0.1,
        clearProps: 'all',
      }
    )
    .call(
      () => {
        splitTitle.intro.play()
      },
      undefined,
      '-=0.2'
    )
    .fromTo(
      [linkRef.value],
      {
        x: '-20px',
        opacity: 0,
      },
      {
        duration: 0.35,
        ease: 'power3.out',
        x: '0px',
        opacity: 1,
        clearProps: 'all',
      },
      '+=0.25'
    )
}

const createLeaveTl = () =>
  gsap
    .timeline({
      paused: true,
    })
    .add('start')
    .fromTo(
      [linkRef.value],
      {
        x: '0px',
        opacity: 1,
      },
      {
        duration: 0.35,
        ease: 'power3.out',
        x: '20px',
        opacity: 0,
      }
    )
    .call(
      () => {
        splitTitle.outro.play()
      },
      undefined,
      'start'
    )
    .fromTo(
      // [headlineRef.value, linkRef.value],
      // ? remove if transition
      [shakerRef.value, headlineRef.value],
      {
        y: '0px',
        opacity: 1,
      },
      {
        duration: 0.35,
        ease: 'power3.out',
        y: '-20px',
        opacity: 0,
        stagger: 0.1,
      },
      'start+=0.25'
    )
    .set(titleRef.value || null, { opacity: 0 })
    .call(() => {
      splitTitle.clear()
    })

const onCaseClick = async () => {
  logger.log(window.scrollY)
  if (window.scrollY > 0) {
    await gsap
      .to(window, {
        scrollTo: { x: 0, y: 0 },
        duration: 0.5,
        ease: 'power2.in.out',
      })
      .then()
  }
  await createLeaveTl().play().then()
  router.push(currentCase.value.url)
}

const onShake = async () => {
  let nextIndex = caseIndex.value + 1

  if (nextIndex >= cases.length) {
    nextIndex = 0
  }

  if (nextIndex === caseIndex.value) {
    onShake()
  } else {
    // TODO: move color change to Poster and sync with animation.
    // Or pass durations from here to children (plus facile, moins clean)…
    // aka outDuration (ou similaire) === bon compromis ?

    // Make it leave
    createLeaveTl().play().then()

    // Update index and trigger poster change
    await wait(0.35)
    caseIndex.value = nextIndex
    poster.change?.(cases[caseIndex.value])

    // Wait for template titleRef update.
    // Then, hide it befaore animation
    nextTick(async () => {
      gsap.set(titleRef.value || null, { opacity: 0 })

      // Trigger colors transition
      setColors(currentCase.value.colors, 1.2)
      await wait(1.6)
      // Dispatch color change event
      emit('color-change', currentCase.value.colors)

      // Update SplitTitle with new content
      if (titleRef.value) {
        splitTitle = createSplitTitle(titleRef.value)
      }

      // Make it appear
      await createAppearTl().play().then()
    })
  }
}

onMounted(async () => {
  if (
    !shakerRef.value ||
    !headlineRef.value ||
    !titleRef.value ||
    !linkRef.value
  ) {
    return
  }

  splitTitle = createSplitTitle(titleRef.value)

  const appearTl = createAppearTl()

  poster.move?.({ template: 'home' }, false)
  setColors(currentCase.value.colors)

  await poster.init?.(currentCase.value)
  await appearTl.play().then()
})
</script>

<style lang="scss" scoped>
.home-case,
[class*='home-case--'] {
  position: relative;
  min-height: calc(100 * var(--vh));
}

.home-case__content {
  position: absolute;
  // right: $home-header-posts-width + 4rem;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: $home-header-bottom;
}

.shaker {
  margin-bottom: 2rem;
  margin-left: -1.2rem;
  background: transparent;
  border: 0;
}

.case__headline {
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.case__title {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 30px, xxl: 50px));
}

.case__link {
  display: inline-block;
  cursor: pointer;
  fill: var(--c-foreground);
}
</style>
