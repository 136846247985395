<template>
  <div
    :key="`about-${refresh}`"
    ref="rootRef"
    :class="{ 'is-sticky': isSticky, 'is-hovering': isHovering }"
    class="home-about m-d"
  >
    <div class="wrapper">
      <div
        ref="contentRef"
        :style="`height: ${contentHeight}px`"
        class="home-about__content"
      >
        <h2 ref="titleRef" class="section-label title">{{ content.title }}</h2>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div ref="textRef" class="text" v-html="content.htmltext"></div>
      </div>
    </div>
    <canvas
      id="castle"
      ref="canvasRef"
      :style="`height: ${contentHeight}px`"
      class="home-about__castle"
    ></canvas>

    <GCtaRounded
      ref="cta"
      :text="
        isSticky
          ? chrome.language === 'en'
            ? 'validate'
            : 'valider'
          : chrome.language === 'en'
          ? 'customize the castle'
          : 'Personnaliser'
      "
      :repeat="isSticky ? 4 : 2"
      :offset="isSticky ? 12.5 : 25"
      :icon="isSticky ? 'check' : 'castle'"
      name="read-case"
      size="large"
      class="home-about__cta"
      @click.stop.prevent="isSticky ? onExit() : onEnter()"
    />
  </div>
</template>

<script setup lang="ts">
import debounce from 'debounce'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { Color, Vector3 } from 'three'
import { ref, onMounted, PropType, watch, nextTick, onBeforeUnmount } from 'vue'
import { useChromeStore } from '@/stores/chrome'
const chrome = useChromeStore()

import CtaRoundedVue from '@/components/global/CtaRounded.vue'

// import { ua } from '@/config/app'
import { World } from '@/components/castle/World'
import { Colors } from '@/types'
import { CastleData } from '@/types/castle'
import { Content } from '@/types/views/home'
import { useRaf, useResize, useViewport, ResizeCallback } from '@/utils/aware'
import { getOffset } from '@/utils/dom'
import { useUiStore } from '@/stores/ui'
import { getColors } from '@/modules/colors'
import { push } from '@/utils/tracking'

interface ContentData {
  split?: SplitText
  tl?: gsap.core.Timeline
}

// gsap.registerPlugin(Observer)
gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  content: {
    type: Object as PropType<Content['about']>,
    required: true,
  },
  colors: {
    type: Object as PropType<Colors>,
    required: true,
  },
})

const { height } = useViewport()

const ui = useUiStore()

const isHovering = ref(false)
const cta = ref<InstanceType<typeof CtaRoundedVue>>()
const rootRef = ref<HTMLDivElement>()
const contentRef = ref<HTMLDivElement>()
const titleRef = ref<HTMLHeadingElement>()
const textRef = ref<HTMLDivElement>()
const canvasRef = ref<HTMLCanvasElement>()
const refresh = ref(0)
const isSticky = ref(false)
let isMounted = false

let tl: gsap.core.Timeline | undefined
let tlCastle: gsap.core.Timeline | undefined

const contentData: ContentData = {}
// let isContentAnimated = false

const castleData: CastleData = {
  bufferX: 2.5,
  width: 0,
  height: 0,
  size: new Vector3(0),
  viewport: { width: 0, height: 0 },
}
const contentHeight = height.value

const toggleCta = () =>
  gsap.to(cta.value?.$el, {
    duration: 0.3,
    opacity: 0,
    yoyo: true,
    repeat: 1,
  })

const onEnter = () => {
  hideUi()
  push({ event: 'castle_configurator' })
  gsap.to(window, {
    scrollTo: {
      y: canvasRef.value,
      autoKill: false,
    },
    onComplete: () => {
      toggleCta().eventCallback('onRepeat', () => {
        // gsap.set(cta.value?.$el, { bottom: '2rem' })
        castleData.world?.switchConfig()
        ui.toggleScroll(false)
        isSticky.value = true
      })
    },
  })
}

const onExit = () => {
  toggleCta().eventCallback('onRepeat', () => {
    // gsap.set(cta.value?.$el, { bottom: '10rem' })
    castleData.world?.switchConfig()
    hideUi()?.reverse()
    isSticky.value = false
    ui.toggleScroll(true)
  })
}

const hideUi = () => {
  const header = document.querySelector('.header')

  if (!header) {
    return null
  }

  return gsap.fromTo(
    [header, textRef.value, titleRef.value],
    { opacity: 1 },
    {
      duration: 0.25,
      opacity: 0,
    }
  )
}

const initContent = (rootEl: HTMLDivElement) => {
  rootEl.style.width = ''
  const { width: rootWidth } = rootEl.getBoundingClientRect()

  rootEl.style.width = `${Math.floor(rootWidth)}px`

  const p = rootEl.querySelector('p') as HTMLParagraphElement
  const keywords = p.querySelectorAll('strong')

  const split = new SplitText(p, {
    type: 'words',
    wordsClass: 'word',
  })
  const { words } = split

  const sloganItems: HTMLElement[] = []
  const sloganEl = document.createElement('div')
  const punctuationItems: HTMLElement[] = []

  sloganEl.classList.add('slogan')
  rootEl.appendChild(sloganEl)

  keywords.forEach((keyword, i) => {
    const cloneEl = keyword.cloneNode(true) as HTMLElement

    sloganEl.appendChild(cloneEl)
    sloganItems.push(cloneEl)

    const punctuationEl = document.createElement('span')

    if (i < 2) {
      punctuationEl.textContent = ', '
    } else {
      punctuationEl.textContent = '.'
    }
    punctuationEl.style.opacity = '0'

    sloganEl.appendChild(punctuationEl)
    punctuationItems.push(punctuationEl)
  })

  keywords.forEach((keyword, i) => {
    const clone = sloganItems[i]
    const { top: originalTop, left: originalLeft } = getOffset(keyword)
    const { top: cloneTop, left: cloneLeft } = getOffset(clone)

    clone.style.transform = `translate(${originalLeft - cloneLeft}px, ${
      originalTop - cloneTop
    }px)`

    keyword.style.visibility = 'hidden'
  })

  return { textItems: words, sloganItems, punctuationItems }
}

const animateContent = ({
  textItems,
  sloganItems,
  punctuationItems,
}: {
  textItems: Element[]
  sloganItems: Element[]
  punctuationItems: Element[]
}) => {
  const tl = gsap.timeline({
    paused: true,
    // onStart() {
    //   isContentAnimated = true
    // },
    // onComplete() {
    //   isContentAnimated = false
    // },
  })

  tl.add('start')
    .to(textItems, {
      duration: 2,
      opacity: 0,
      // yPercent: -50,
      translateY() {
        // eslint-disable-next-line no-mixed-operators
        return -300 + Math.random() * 50
      },
      rotateZ() {
        // eslint-disable-next-line no-mixed-operators
        return 15 + Math.random() * 75
      },
      translateX() {
        // eslint-disable-next-line no-mixed-operators
        return 100 + Math.random() * 50
      },
      // ? optional ?
      rotateX() {
        // eslint-disable-next-line no-mixed-operators
        return 15 + Math.random() * 25
      },
      translateZ() {
        // eslint-disable-next-line no-mixed-operators
        return 50 + Math.random() * 50
      },
      // Heavy blur… :)
      // filter: 'blur(4px)',
      stagger: 0.08,
      ease: 'power3.in',
    })
    .add('slogan', '+=1')
    .to(
      sloganItems,
      {
        duration: 2,
        x: 0,
        xPercent: 0,
        stagger: 0.1,
        ease: 'power3.inOut',
      },
      'slogan'
    )
    .to(
      sloganItems,
      {
        duration: 1.6,
        y: 0,
        yPercent: 0,
        stagger: 0.1,
        ease: 'power3.inOut',
      },
      'slogan+=0.5'
    )
    .add('punctuation', '-=0.5')
    .to(punctuationItems, {
      duration: 0.25,
      opacity: 1,
      stagger: 0.3,
      ease: 'power3.out',
    })

  return tl
}

const initCastle = async (canvas: HTMLCanvasElement) => {
  const world = new World({
    canvas,
    config: {
      rotation: true,
      panels: true,
      background: true,
      colors: {
        primary: new Color(props.colors.foreground),
        secondary: new Color(props.colors.background),
      },
    },
  })

  await world.loading

  // const boundingBox = new Box3().setFromObject(world.castle.holder)

  castleData.world = world
  // logger.log('📏', castleData.world.castle.holder.position)

  return world.castle
}

const updateCastle = () => {
  const castle = castleData.world?.castle

  if (!castle) {
    return
  }

  castle.updateColors(props.colors.foreground, props.colors.background)
}

// const animateCastle = (canvas: HTMLDivElement) => {
//   const start = 0
//   const tl = gsap.timeline({
//     paused: true,
//   })

//   tl.add('start').to(
//     canvas,
//     {
//       duration: 1 - start,
//       yPercent: -100,
//     },
//     `start+=${start}`
//   )

//   return tl
// }

const initAll = async () => {
  if (!contentRef.value || !textRef.value || !canvasRef.value) {
    return
  }

  // Kill previous animations (refresh)
  tl?.kill()
  contentData.tl?.kill()
  tlCastle?.kill()

  const items = initContent(textRef.value)
  contentData.tl = animateContent(items)

  await initCastle(canvasRef.value)
  // tlCastle = animateCastle(canvasRef.value)

  // let tlCastleProgress = 0
  // let shouldAnimateCastle = false
  // let isPageScrolling = true

  tl = gsap.timeline({
    scrollTrigger: {
      trigger: contentRef.value,
      // markers: import.meta.env.VITE_RELEASE === 'development',
      start: 'center center',
      end: '+=300%',
      scrub: true,
      pin: true,
      onUpdate: self => {
        contentData.tl?.progress(self.progress * 1.2)
        // shouldAnimateCastle = self.progress > 0.65
      },
      // onEnter: () => {
      //   isPageScrolling = false
      // },

      // onLeave: () => {
      //   isPageScrolling = true
      // },
    },
  })

  initObserver()
}

const refreshContent = debounce(() => {
  // Force :key to update
  refresh.value += 1

  nextTick(() => {
    ScrollTrigger.refresh()
    ScrollTrigger.clearScrollMemory()
    initAll()
  })
})

const updateWorldColors = () => {
  const colors = getColors()
  const primary = new Color(colors.foreground)
  const secondary = new Color(colors.background)
  castleData.world?.updateColors(primary, secondary, true)
}

let obs: MutationObserver | null
const initObserver = () => {
  let timeout: ReturnType<typeof setTimeout>
  obs = new MutationObserver(() => {
    timeout && clearTimeout(timeout)
    timeout = setTimeout(() => {
      updateWorldColors()
    }, 100)
  })
  const observe = () => {
    obs &&
      obs.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ['style'],
      })
  }
  observe()

  ScrollTrigger.create({
    trigger: canvasRef.value,
    onEnter: () => {
      obs && obs.disconnect()
    },
    onEnterBack: () => {
      obs && obs.disconnect()
    },
    onLeave: () => {
      observe()
    },
    onLeaveBack: () => {
      observe()
    },
  })
}

const onResize: ResizeCallback = ({ width, height }) => {
  if (!isMounted) {
    return
  }

  castleData.width = width
  castleData.height = height
  castleData.world?.resize(castleData.width, castleData.height)

  refreshContent()
}

useResize(onResize)

const onRaf = () => {
  if (!castleData.world) {
    return
  }

  castleData.world.render()
  isHovering.value = castleData.world.isHovering
}

watch(() => props.colors, updateCastle)

onMounted(() => {
  // https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.normalizeScroll()
  // ScrollTrigger.normalizeScroll(ua?.getOS().name === 'iOS')
  ScrollTrigger.config({
    ignoreMobileResize: true,
    autoRefreshEvents: 'none',
  })

  nextTick(() => {
    initAll()
    useRaf(onRaf)

    isMounted = true
  })
})

onBeforeUnmount(() => {
  if (obs) {
    obs.disconnect()
    obs = null
  }
})
</script>

<style lang="scss" scoped>
/* stylelint-disable declaration-no-important */
.home-about,
[class*='home-about--'] {
  position: relative;

  &.is-hovering {
    cursor: pointer;
  }

  :deep(.pin-spacer) {
    pointer-events: none;
  }
}

.home-about__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 100vh;
  text-align: center;
}

.home-about__castle {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh) * 100);
  pointer-events: none;

  .is-sticky & {
    z-index: 1;
    pointer-events: auto;
  }
}

.home-about__cta {
  @include center-x;

  z-index: 2;
  bottom: 1.5%;
  display: inline-block;

  :deep(.text) {
    font-size: 1rem;
  }

  :deep(svg) {
    fill: var(--c-background);
  }

  :deep(svg .circle) {
    fill: var(--c-foreground);
    stroke: var(--c-background);
  }

  @include mq($until: s) {
    :deep(svg) {
      width: 8rem !important;
      height: 8rem !important;
    }
  }
}

.title {
  margin-bottom: 3em;
}

.text {
  position: relative;
  font-family: $ff-alt;
  font-size: 2rem;

  :deep(p) {
    perspective: 300px;
  }

  :deep(strong) {
    display: inline-block;
  }

  :deep(.word) {
    transform-style: preserve-3d;
  }

  :deep(.slogan) {
    @include get-all-space;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include mq(xs) {
    font-size: 2.4rem;
  }

  @include mq(s) {
    font-size: 3.6rem;
  }

  @include mq(l) {
    font-size: 4.2rem;
  }
}
</style>
