<template>
  <div class="homepage" :style="`margin-top: -${uiStore.headerHeight}px;`">
    <h1 class="h-visually-hidden">EPIC Agency</h1>
    <div class="homepage__header wrapper">
      <HomeCase :content="homeContent" @color-change="onColorChange" />
      <!-- <HomePosts :posts="homeContent.posts" /> -->
      <hr />
    </div>
    <div class="homepage__showreel wrapper m-d">
      <span class="section-label">showreel</span>
      <VVideo
        :content="video"
        :options="{ controls: ['play', 'mute', 'fullscreen'] }"
        class=""
      />
    </div>
    <hr class="wrapper" />
    <HomeAbout :content="homeContent.about" :colors="colors" />
    <div class="wrapper">
      <hr />
      <UiCta :content="homeContent.cta" class="m-d" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import HomeCase from '@/components/home/Case.vue'
// import HomePosts from '@/components/home/Posts.vue'
import HomeAbout from '@/components/home/About.vue'
import VVideo from '@/components/ui/Video.vue'
import UiCta from '@/components/ui/Cta.vue'
import { useResourceStore } from '@/stores/resource'
import { useUiStore } from '@/stores/ui'
import { Colors } from '@/types'
import { Content } from '@/types/views/home'

defineProps(['content'])

const resourceStore = useResourceStore()
const uiStore = useUiStore()

const homeContent = resourceStore.content as Content
const colors = ref({ foreground: '#000', background: '#000' })

const video = {
  id: '974135712',
  icon: 'play',
}

const onColorChange = (newColors: Colors) => {
  colors.value = newColors
  resourceStore.content.colors = newColors
}
</script>

<style lang="scss" scoped>
.homepage {
  overflow: hidden;
}

.homepage__showreel {
  // prettier-ignore
  @include fluid(gap,(xxs: 30px, xxl: 50px));

  display: flex;
  flex-direction: column;
  text-align: center;

  hr {
    width: 100%;
  }
}

.homepage__header {
  @include mq($home-header-bp) {
    position: relative;
    height: calc(100 * var(--vh));
  }
}
</style>
